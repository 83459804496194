import React, { useState, useEffect, useRef } from 'react';
import OpenAI from 'openai';
import { XMarkIcon, ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/solid';
import Bubble from './chatbox/Bubble';
import Rounded from './chatbox/Rounded';
import './App.css';

const initialMessages = [
  { role: 'ai', text: '# Hi, Welcome to AMET Education. You can ask me anything about studying abroad.' }
];

const ZILTER_CHATBOT_DB = JSON.parse(sessionStorage.getItem('zilter_chatbot_db'));
const DEFAULT_OPENAI_KEY = 'sk-proj-8PjoE0rAunjqimoxg6GhT3BlbkFJW5myxddNveyhGKo20gWn';
const DEFAULT_ASSISTANT_ID = 'asst_ryDb4Sa5AuGb9nF8YmPwvUVH';

let openai = new OpenAI({ apiKey: DEFAULT_OPENAI_KEY, dangerouslyAllowBrowser: true });

function App() {
  const messagesEndRef = useRef(null);
  const intervalRef = useRef(null);
  const chatRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState(initialMessages);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState({});
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [design, setDesign] = useState('rounded');
  const [bookingLink, setBookingLink] = useState('');
  const [bookingLinkText, setBookingLinkText] = useState('Book a meeting');
  const [color, setColor] = useState('#bc232b');
  const [chatIcon, setChatIcon] = useState(null);
  const [thread, setThread] = useState(ZILTER_CHATBOT_DB);
  const [loading, setLoading] = useState(false);

  const toggleChat = () => {
    const isOpening = !open;  // Determine if chat is being opened
    setOpen(isOpening);
    
    if (isOpening) {
      setTimeout(() => {
        scrollToBottom();
      }, 300);  // Add a short delay to ensure content is rendered
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Fetch messages from the OpenAI API for a given thread
  const fetchMessages = async (thread) => {
    console.log("fetchMessages")
    try {
      const response = await openai.beta.threads.messages.list(thread.id);
      if (response?.data?.length) {
        const fetchedMessages = response.data.map((msg) => ({
          role: msg.role === 'user' ? 'user' : 'ai',
          text: msg.content[0].text.value,
        }));
        setMessages([...initialMessages, ...fetchedMessages.reverse()]);
        setThread(thread);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  // Fetch assistant response for a thread and run ID
  const fetchAssistantResponse = async (threadId, runId) => {
    try {
      const runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);

      if (runStatus.status === 'completed') {
        const response = await openai.beta.threads.messages.list(threadId);
        const botResponse = response.data[0].content[0].text.value;
        setMessages((prev) => [
          ...prev,
          { role: 'ai', text: botResponse }
        ]);
        setLoading(false)
      } else {
        setTimeout(() => fetchAssistantResponse(threadId, runId), 200);
      }
    } catch (error) {
      console.error('Error fetching assistant response:', error);
      setLoading(false)
    }
  };

  // Send a message and trigger assistant response
  const sendMessage = () => {
    const newMessages = [...messages, { role: 'user', text: message }];
    setMessages(newMessages);
    setMessage('');
    askAssistant(message);
  };

  const askAssistant = async (question) => {
    setLoading(true);
    try {
      let chatThread = thread;
      if (!chatThread) {
        chatThread = await openai.beta.threads.create();
        sessionStorage.setItem('zilter_chatbot_db', JSON.stringify(chatThread));
        setThread(chatThread);
      }

      await openai.beta.threads.messages.create(chatThread.id, { role: 'user', content: question });
      const run = await openai.beta.threads.runs.create(chatThread.id, { assistant_id: DEFAULT_ASSISTANT_ID });
      fetchAssistantResponse(chatThread.id, run.id);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Handle Enter key press for sending messages
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') sendMessage();
  };

  // Fetch random user details (placeholder logic, should be replaced with actual user data)
  const fetchUserDetails = async () => {
    try {
      const response = await fetch('https://randomuser.me/api/?nat=gb&gender=female');
      const data = await response.json();
      const fetchedUser = {
        name: data.results[0].name.first,
        picture: data.results[0].picture.thumbnail,
      };
      if (window?.name) {
        fetchedUser.name = window?.name
      }
      if (window?.picture) {
        fetchedUser.picture = window?.picture
      }
      setUser(fetchedUser);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    // Fetch existing messages if thread exists
    if (ZILTER_CHATBOT_DB?.id) {
      fetchMessages(ZILTER_CHATBOT_DB);
    }
    fetchUserDetails();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Load global configurations for dark mode, design, and other settings
  useEffect(() => {
    if (window?.dark) setIsDarkMode(window?.dark);
    if (window?.design) setDesign(window?.design);
    if (window?.project === 'amet') {
      openai = new OpenAI({ apiKey: DEFAULT_OPENAI_KEY, dangerouslyAllowBrowser: true });
    }
    setBookingLink(window?.booking_link || '');
    setBookingLinkText(window?.booking_link_text || 'Book a meeting');
    setColor(window?.color || '#bc232b');
    setChatIcon(window?.chat_icon || null);
  }, []);

  return (
    <div className={isDarkMode ? 'dark' : 'light'}>
      {chatIcon ? (
        <div onClick={toggleChat} className="zc-chat-icon">
          <img src={chatIcon} alt="Chat Icon" className="icon-size" />
        </div>
      ) : (
        <div onClick={toggleChat} className="zc-chat-trigger">
          {open ? <XMarkIcon className="icon-close" /> : <ChatBubbleOvalLeftEllipsisIcon className="icon-chat" />}
        </div>
      )}

      {open && (
        <div className='fixed md:bottom-[60px] md:right-[70px] bottom-0 right-0 flex items-end justify-center md:w-[500px] w-full h-full z-[9999]'>
          {design === 'rounded' ? (
            <Rounded
              messagesEndRef={messagesEndRef}
              messages={messages}
              user={user}
              setMessage={setMessage}
              message={message}
              onSendMessage={sendMessage}
              handleKeyDown={handleKeyDown}
              setOpen={setOpen}
              bookingLink={bookingLink}
              bookingLinkText={bookingLinkText}
              color={color}
              loading={loading}
            />
          ) : (
            <Bubble
              messagesEndRef={messagesEndRef}
              messages={messages}
              user={user}
              setMessage={setMessage}
              message={message}
              onSendMessage={sendMessage}
              handleKeyDown={handleKeyDown}
              setOpen={setOpen}
              bookingLink={bookingLink}
              bookingLinkText={bookingLinkText}
              color={color}
              loading={loading}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default App;
