import React from "react";
import { XMarkIcon } from '@heroicons/react/24/solid'
import Markdown from 'react-markdown'
import rehypeExternalLinks from 'rehype-external-links'
import colorVariants from "./Color";

function Rounded({
  user,
  setMessage,
  message,
  onSendMessage,
  messages,
  messagesEndRef,
  handleKeyDown,
  setOpen,
  bookingLink,
  bookingLinkText,
  color,
  loading
}) {
  return (
    <div className="flex md:h-[70vh] h-full w-full flex-col bg-slate-300 shadow-xl rounded-xl dark:bg-slate-900">
      <div
        className="w-full rounded-lg border border-slate-200 bg-slate-200 px-1 py-2 shadow dark:border-slate-700 dark:bg-slate-800 mb-2"
      >
        <div className="flex items-center justify-between">
          <div className="flex">
            <div className="relative inline-flex">
              <span
                className="absolute bottom-0 right-0 h-3 w-3 rounded-full border bg-green-600 dark:border-slate-900 dark:bg-green-600 dark:text-slate-100"
              ></span>
              <img
                src={user?.picture}
                // src={'https://media.licdn.com/dms/image/D4D03AQFNEvlOQ7fQJA/profile-displayphoto-shrink_100_100/0/1688460316084?e=1715817600&v=beta&t=q3cWMNL3D1Bo0Y8niyGxNl9WTe_MaA2kRjQn4PCWNzM'}
                alt="user"
                className="h-10 w-10 rounded-full bg-slate-400 dark:border-slate-700"
              />
            </div>
            <div className="ml-4 flex flex-row gap-y-2">
              <h3 className="text-sm font-bold text-slate-900 dark:text-slate-200 flex items-center">
                {user?.name}
              </h3>
            </div>
          </div>
          <XMarkIcon className='cursor-pointer size-6 text-slate-900 dark:text-slate-200' onClick={() => setOpen(false)} /> 
        </div>
      </div>
      <div
        id="chats"
        className="flex-1 overflow-y-auto rounded-xl bg-slate-200 p-4 text-sm leading-6 text-slate-900 dark:bg-slate-800 dark:text-slate-300 sm:text-base sm:leading-7 h-[70vh] overflow-y-scroll"
      >
        {messages.map((item => {
          let itemmessage = item?.text.replace("${Book a Meeting}", `[${bookingLinkText}](${bookingLink})`);
          if(item.role === 'user') {
            return (
              <div className="flex flex-row px-2 py-4 sm:px-4">
                <img
                  alt=''
                  className="mr-2 flex h-8 w-8 rounded-full sm:mr-4"
                  src="https://dummyimage.com/256x256/363536/ffffff&text=Y"
                />
      
                <div className="flex flex-col max-w-3xl items-center">
                  <Markdown>{itemmessage}</Markdown>
                </div>
              </div>)
          } else {
            return (
              <div
                className="mb-4 flex rounded-xl bg-slate-50 px-2 py-6 dark:bg-slate-900 sm:px-4"
              >
                <img
                  alt=''
                  className="mr-2 flex h-8 w-8 rounded-full sm:mr-4"
                  src={`https://dummyimage.com/256x256/354ea1/ffffff&text=${user?.name ? Array.from(user?.name)[0] : 'A'}`}
                />
      
                <div className="flex flex-col max-w-3xl items-center rounded-xl">
                  <Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>{itemmessage}</Markdown>
                </div>
              </div>
            )
          }
        }))}
        {loading ? 
              <div
                className="mb-4 flex rounded-xl bg-slate-50 px-2 py-6 dark:bg-slate-900 sm:px-4"
              >
                <img
                  alt=''
                  className="mr-2 flex h-8 w-8 rounded-full sm:mr-4"
                  src={`https://dummyimage.com/256x256/354ea1/ffffff&text=${user?.name ? Array.from(user?.name)[0] : 'A'}`}
                />
      
                <div className="flex flex-col max-w-3xl items-center rounded-xl">
                  <Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>Typing...</Markdown>
                </div>
              </div> : null}
        <div ref={messagesEndRef} >
        </div>
      </div>
      {/* Prompt message input */}
      <form className="mt-2" onSubmit={e => { e.preventDefault(); }}>
        <label htmlFor="chat-input" className="sr-only">Enter your prompt</label>
        <div className="relative">
          {/* <button
            type="button"
            className="absolute inset-y-0 left-0 flex items-center pl-3 text-slate-500 hover:text-blue-500 dark:text-slate-400 dark:hover:text-blue-500"
          >
            <svg
              aria-hidden="true"
              className="h-5 w-5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M9 2m0 3a3 3 0 0 1 3 -3h0a3 3 0 0 1 3 3v5a3 3 0 0 1 -3 3h0a3 3 0 0 1 -3 -3z"
              ></path>
              <path d="M5 10a7 7 0 0 0 14 0"></path>
              <path d="M8 21l8 0"></path>
              <path d="M12 17l0 4"></path>
            </svg>
            <span className="sr-only">Use voice input</span>
          </button> */}
          <input
            id="chat-input"
            className="h-16 block w-full resize-none rounded-xl border-none bg-slate-200 p-4 pl-10 pr-20 text-sm text-slate-900 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-slate-800 dark:text-slate-200 dark:placeholder-slate-400 dark:focus:ring-blue-500 sm:text-base"
            placeholder="Enter your prompt"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyDown={(event) => handleKeyDown(event)}
          ></input>
          <button
            onClick={() => onSendMessage()}
            type="button"
            className={`${colorVariants[color]} focus:outline-none focus:ring-4 h-10 border-none d-flex align-items-center absolute bottom-2 right-2.5 rounded-lg px-4 py-2 text-sm font-medium text-slate-200 sm:text-base`}
          >
            Send <span className="sr-only">Send message</span>
          </button>
        </div>
      </form>
    </div>
  );
}

export default Rounded;
